<template>
	<div>
		<frame :top1="top1" :top2="top2">
			<!-- 头部 -->
			<div class="head">
				<div class="left">
					<b class="left_tit">分销等级</b>
				</div>
				<el-form ref="form" :model="formsel" label-width="100px" class="sele">

					<el-form-item label="请选择商户:" style="width: 300px;">
						<el-select                       v-model="storeid"                       placeholder="请选择商户"
							                                           >
							                      <el-option                         v-for="item in businessList"
								                        :key="item.storeid"                         :label="item.s_name"
								                        :value="item.storeid" @click.native="shopType(item.storeid)"
								                      >
								                      </el-option>
							                    </el-select>
					</el-form-item>
				</el-form>
				<div class="right">
					<el-button class="refresh" @click="refresh()" icon="el-icon-refresh">刷新</el-button>
				</div>
			</div>
			<!-- 筛选部分 -->
			<el-form ref="form" :model="form" label-width="100px" class="sele">
				<el-form-item label="加入时间">
					<el-col :span="11">
						<el-date-picker type="date" placeholder="请选择时间" v-model="form.date1" style="width:120%;">
						</el-date-picker>
					</el-col>
					<!-- <el-col class="line" :span="2">-</el-col> -->

				</el-form-item>

				<el-form-item label="分销商信息">
					<el-input v-model="form.name" placeholder="微信昵称/手机号/id"></el-input>
				</el-form-item>
				<el-form-item label="分销商等级">
					<el-select v-model="form.region" placeholder="全部">
						<el-option label="添加货品" value="添加货品"></el-option>
						<el-option label="退货时" value="退货时"></el-option>
					</el-select>
				</el-form-item>


				<el-form-item style="margin-right: 60px;">
					<el-button type="primary" @click="onSubmit">搜索</el-button>
					<!-- <el-button>取消</el-button> -->
				</el-form-item>
			</el-form>
			<!-- 表格头部 -->
			<div class="table_head">
				<div class="table_head_left">
					<i class="el-icon-s-unfold"></i>
					数据列表
				</div>
				<div class="table_head_right">
					<ul>
						<li>
							<el-button size="mini" @click="show = !show">
								添加
							</el-button>
						</li>
					</ul>
					<transition name="el-fade-in">
						<div v-show="show" class="transition-box">
							<el-form ref="form" :model="sizeForm" label-width="80px" size="mini">
								<div class="act_tit">添加标签</div>


								<div class="listcont">
									<div class="shuai">
										<!-- <div class="selname"></div> -->
										<el-form-item label="选择商户：" label-width="120px" style="margin-top: 5px;">
										</el-form-item>
										<el-select                       v-model="storeid"
											                      placeholder="请选择商户"                       
											                    >
											                      <el-option
												                        v-for="item in businessList"
												                        :key="item.storeid"
												                        :label="item.s_name"
												                        :value="item.storeid"
												@click.native="shopTypes(item.storeid)"                       >
												                      </el-option>
											                    </el-select>
									</div>


								</div>

								<el-form-item label="等级名称：" label-width="120px">
									<el-input v-model="gd_name" style="width: 200px;margin-right: 450px;">
									</el-input>
								</el-form-item>
								<!-- <div> -->
								<!-- <div style="display: flex;"> -->
								<el-form-item label="升级条件：" label-width="120px">

									<div style="display: flex;">
										<el-form-item label="累计客户数" label-width="150px">
										</el-form-item>
										<el-form-item label="达到：" label-width="120px">
											<el-input v-model="shuai" @input="niu()"></el-input>
										</el-form-item>
									</div>
									<div style="display: flex;">
										<el-form-item label="累计消费额" label-width="150px">
										</el-form-item>
										<el-form-item label="达到：" label-width="120px">
											<el-input v-model="smoney" @input="niu()"></el-input>
										</el-form-item>
									</div>
									<div style="display: flex;">
										<el-form-item label="分佣订单数" label-width="150px">
										</el-form-item>
										<el-form-item label="达到：" label-width="120px">
											<el-input v-model="fnum" @input="niu()"></el-input>
										</el-form-item>
									</div>
									<div style="display: flex;">
										<el-form-item label="分佣订单额" label-width="150px">
										</el-form-item>
										<el-form-item label="达到：" label-width="120px">
											<el-input v-model="fmoney" @input="niu()"></el-input>
										</el-form-item>
									</div>
									<div style="display: flex;">
										<el-form-item label="已结算佣金" label-width="150px">
										</el-form-item>
										<el-form-item label="达到：" label-width="120px">
											<el-input v-model="jmoney" @input="niu()"></el-input>
										</el-form-item>
									</div>


								</el-form-item>
								<el-form-item label="默认佣金比例：" label-width="120px">

									<div style="display: flex;">

										<el-form-item label="一级分销默认比例 *" label-width="150px">
											<el-input v-model="o_level" style="width: 50px;"></el-input>
										</el-form-item>
									</div>
									<div style="display: flex;">

										<el-form-item label="二级分销默认比例 *" label-width="150px">
											<el-input v-model="t_level" style="width: 50px;"></el-input>
										</el-form-item>
									</div>
									<div style="display: flex;">
										<el-form-item label="三级分销默认比例 *" label-width="150px">
											<el-input v-model="th_level" style="width: 50px;"></el-input>
										</el-form-item>
									</div>
								</el-form-item>
								<el-form-item size="large">
									<el-button type="primary" @click="onSubmit">立即创建</el-button>
									<el-button @click="show = false">取消</el-button>
								</el-form-item>
							</el-form>
						</div>
					</transition>

				</div>
			</div>
			<!-- 表格渲染 -->
			<div class="table">
				<!-- 设置表格高度  height="500" -->
				<el-table :data="labelList" border style="width: 85%; margin-left: 200px; text-align: center">
					<el-table-column prop="gd_name" label="等级名称" align="center">
					</el-table-column>
					<el-table-column prop="o_level" label="一级" align="center">
					</el-table-column>


					<el-table-column prop="o_level" label="二级" align="center">
					</el-table-column>
					<el-table-column prop="th_level" label="三级" align="center">
					</el-table-column>
					<el-table-column prop="content" label="升级条件" align="center">
					</el-table-column>
					<el-table-column prop="operation" label="操作" width="180" align="center">
						<template slot-scope="scope">
							<span style="margin-left: 10px" @click="
                  edit(
                    scope.row.gd_name,
					scope.row.o_level,
					scope.row.t_level,
					scope.row.th_level,
					scope.row.dg_id,
					scope.row.storeid
                  )
                ">
								编辑
							</span>

						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- 分页 -->
			<div class="block">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="titlePage">
				</el-pagination>
				<div></div>
			</div>

		</frame>
	</div>
</template>

<script>
	import frame from "../public/Frame.vue";
	// let shu='累计客户数';
	export default {

		data() {
			return {
				form: {
					name: '',
					region: '',
					date1: '',
					date2: '',
				},
				formsel: {
					region: ''
				},
				top1: "9-3",
				top2: ["9"],
				flag: 1,
				admin_type: "",
				xstoreid: '',
				labelList: [],
				subtype: [],
				selects: '',
				selectp: '',
				activeTitle: "",
				titlePage:10,
				gd_name: '',
				dg_id: '',
				o_level: '',
				t_level: '',
				th_level: '',
				//选择活动状态
				active_status: "",
				merchantShow: false,
				show: false,
				changeShow: false,
				/*  businessList: [],
				business_id: '', */
				businessList: [{}],
				business_id: "",

				storeList: [{}],
				storeid: "",
				//添加活动
				sizeForm: {
					name: "",
					merchant: "",
					date1: "",
					date2: "",
					endDate1: "",
					endDate2: "",
					delivery: "",
					one: "",
					two: "",
					three: "",
				},


				cur_page: 0,

				tableData: [],


				currentPage4: 1,
				currentpage: "",
				pageSize: 10,
				// tatal: ,
				value2: true,
				l_money_name: '',

				henshuai: {
					'累计客户数': "",
					'累计消费额': "",
					'分佣订单数': "",
					'分佣订单额': "",
					'已结算佣金': "",
				},
				shuai: '',
				smoney: '',
				fnum: '',
				fmoney: '',
				jmoney: '',
			};
		},
		methods: {
			niu() {

				this.henshuai.累计客户数 = this.shuai


				this.henshuai.累计消费额 = this.smoney


				this.henshuai.分佣订单数 = this.fnum


				this.henshuai.分佣订单额 = this.fmoney


				this.henshuai.已结算佣金 = this.jmoney


				console.log(this.henshuai)

			},
			edit(gd_name, o_level, t_level, th_level, dg_id, storeid) {
				this.show = true
				this.gd_name = gd_name
				this.o_level = o_level
				this.t_level = t_level
				this.th_level = th_level
				this.dg_id = dg_id
				this.storeid = storeid
				console.log('555', storeid)
				console.log('did',dg_id)
				let token = this.$storage.getLocal('token');
				this.$request.getMerchantData({
					token
				}).then(r => {
					console.log(123)
					if (r.code == 0) {
						this.businessList = r.data;
						console.log(r)
					}
				})
			},
			//修改上下架
			//刷新页面按钮
			refresh() {
				location.reload();
			},

			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.pageSize = parseInt(`${val}`);
				console.log("361", this.pageSize);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentpage = `${val}`;
			},
			delActive(val) {
				let token = this.$storage.getLocal('token');
				this.$request.memberLabelDel({
					token,
					ml_id: val
				}).then(r => {
					if (r.code == 0) {
						location.reload();
					}
				})
			},
			onSubmit() {
				console.log(this.storeid)
				console.log(this.dg_id)
				// return false
				let token = this.$storage.getLocal('token');
				// if(this.dg_id=='')
				// {
				this.$request.disGradeSet({
					token,
					storeid: this.storeid,
					login_type: this.$storage.getLocal('type'),
					dg_id: this.dg_id,
					gd_name: this.gd_name,
					o_level: this.o_level,
					t_level: this.t_level,
					th_level: this.th_level,
					data: this.henshuai
				}).then(r => {
					if (r.code == 0) {
						console.log(r)
						this.show = false
						location.reload();
					}
				})
				// }

			},

			shopType(value) {
				this.storeid = value
				let token = this.$storage.getLocal('token');
				this.$request.disGradeSel({
					token,
					storeid: value,
					login_type: this.$storage.getLocal('type')
				}).then(r => {
					// console.log(123)
					if (r.code == 0) {
						this.labelList = r.data;
						console.log('567',r)
					}
				})
			},
			// storeidType(value) {
			// 	this.xstoreid = value
			// },
			//编辑活动的提交按钮


		},

		created() {
			let login_type = this.$storage.getLocal('type')
			let token = this.$storage.getLocal('token');
			if (login_type == '1') {
				this.$request.getMerchantData({
					token
				}).then(r => {
					console.log(123)
					if (r.code == 0) {
						this.businessList = r.data;
						console.log(r)

					}
				})
			} else {
				this.$request.memberLabelList({
					token,
					storeid: '',
					login_type: login_type,
					page: '1',
					limit: '10'
				}).then(r => {
					if (r.code == 0) {
						console.log(r)
						this.labelList = r.data.data
					}
				})
			}
		},
		watch: {},
		components: {
			frame,
		},
	};
</script>

<style scoped>
	.head {
		width: 100%;
		height: 45px;
		line-height: 45px;
		background-color: rgb(243, 243, 243);
	}

	.left,
	.right {
		display: inline;
	}

	.left {
		float: left;
		margin-left: 210px;
	}

	.left_tit {
		border-left: 5px solid red;
		font-size: 22px;
		padding: 5px;
	}

	.refresh {
		/* border:1px solid red ; */
		float: right;
		margin-right: 10%;
		margin-top: 2px;
	}

	.screen {
		border: 1px solid #ccc;
		margin-top: 15px;
		width: 85%;
		margin-left: 200px;
		height: 90px;
	}

	.screen_tit {
		height: 40px;
		background-color: rgb(243, 243, 243);
	}

	.screen_left {
		float: left;
		margin-left: 50px;
		margin-top: 10px;
	}

	.screen_right {
		float: right;
		margin-top: 6px;
		margin-right: 10px;
	}

	.screen_cont {
		margin-left: 20px;
		padding-top: 10px;
		font-size: 18px;
	}

	.transition-box {
		position: absolute;
		z-index: 2;
		top: 100px;
		left: 27%;
		width: 800px;
		height: 700px;

		box-shadow: 0px 0px 10px #888888;
		border-radius: 4px;
		background-color: #fff;
		text-align: center;
		color: #fff;
		padding: 40px 20px;
		box-sizing: border-box;
	}

	.act_tit {
		width: 800px;
		height: 50px;
		background-color: rgb(236, 128, 141);
		margin-top: -40px;
		margin-left: -20px;
		margin-bottom: 50px;
		font-size: 20px;
		line-height: 50px;
		display: flex;
		justify-content: space-between;
	}

	.sele {
		display: flex;
		margin-top: 20px;
		margin-left: 200px;
	}

	.table_head {
		height: 40px;
		margin-left: 200px;
		width: 85%;
		border: 1px solid #ccc;
		display: flex;
		justify-content: space-between;
		padding-top: 5px;
	}

	.table_head_left {
		margin-top: 5px;
		margin-left: 20px;
	}

	.table_head_right li {
		float: right;
		height: 30px;

		list-style: none;
		margin-right: 10px;
	}

	.block {
		margin-top: 30px;
	}

	.listcont {
		display: flex;

	}

	.shuai {
		display: flex;
		/* border:1px solid black */
	}

	.selname {
		width: 200px;
		border: 1px solid black;

	}

	.sel {
		/* margin-left: 10px; */
		margin-top: 5px;
	}
</style>
