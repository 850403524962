import { render, staticRenderFns } from "./Retailrank.vue?vue&type=template&id=677bd4ed&scoped=true&"
import script from "./Retailrank.vue?vue&type=script&lang=js&"
export * from "./Retailrank.vue?vue&type=script&lang=js&"
import style0 from "./Retailrank.vue?vue&type=style&index=0&id=677bd4ed&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "677bd4ed",
  null
  
)

export default component.exports